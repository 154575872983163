.joinMain{
    background:linear-gradient(35deg, #696edb, #97a9db 50%, #796ede);
    height:auto;
    padding:10px 22px 15px;
    margin-top: 59px;
}
.joinMainA{
    width:100%;
    height:auto;
    display:flex;
    justify-content:end
}
.joinMainA i{
    color: white;
    font-size:22px
}
.joinMainB{
    display:flex;
    justify-content:center;
    align-items:center
}
.joinMainB button{
    font-family:Roboto;
    border-radius:25px;
    width:58%;
    background:none;
    border:1px solid white;
    padding:3px 10px;
    color:white;
    font-size:14px;
    font-weight:900
}
.tabsForwins{
    height:auto;
    background-color:#f5f5f5;
    box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items: center
}
.tabsWining{
    width:50%;
    height:auto;
    display:flex;
    justify-content:center;
    align-items:center
}
.tabsWining p{
    font-weight:500;
    font-size:15px
}
.tabsLeader{
    width:50%;
    height:auto;
    display:flex;
    justify-content:center;
    align-items:center
}
.tabsLeader p{
    font-weight:500;
    font-size:15px
}
.winningTabMain{
    background-color:rgb(242, 242, 242);
    height:90vh
}
.winningTabMainA{
    height:auto;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
    padding:18px 18px;
    background-color:white
}
.winningTabMainB{
    height: auto;
    padding:8px 11px
}
.winningTabMainC{
    height:auto;
    background-color:white
}
.winningTabMainD{
    height:auto
}
.winningTabMainAPart1{
    color:#212121;
    font-size:16px;
    font-weight:700;
    font-family:Roboto
}
.winningTabMainAPart2{
    color: #23416a;
    font-size: 20px;
    font-weight: 700;
    font-family:Roboto;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items:end;
    line-height:26px
}
.winningTabMainAPart2 span{
    color:#4a4747;
    font-size:12px;
    font-weight:700
}
.winningTabMainB p{
    color:#212529;
    font-size:13px;
    font-weight:500;
    margin-top:0px;
    margin-bottom:0px
}
.winningTabMainC1{
    height:auto;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    padding:0px 30px
}
.winningTabMainC1P1{
    font-size:13px;
    color:#212529;
    margin-bottom:5px;
    margin-top:8px
}
.winningTabMainC1P2{
    font-size:13px;
    color:#23416a;
    font-weight:500;
    font-family:Roboto;
    margin-bottom:5px;
    margin-top:8px
}
.winningTabMainC2{
    height:auto;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    padding:0px 30px
}
.winningTabMainC2P1{
    font-size:13px;
    color:#212529;
    margin-bottom:5px;
    margin-top:8px
}
.winningTabMainC2P2{
    font-size:13px;
    color:#23416a;
    font-weight:500;
    font-family:Roboto;
    margin-bottom:5px;
    margin-top:8px
}
.winningTabMainC3{
    height:auto;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    padding:0px 30px
}
.winningTabMainC3P1{
    font-size:13px;
    color:#212529;
    margin-bottom:5px;
    margin-top:8px
}
.winningTabMainC3P2{
    font-size:13px;
    color:#23416a;
    font-weight:500;
    font-family:Roboto;
    margin-bottom:5px;
    margin-top:8px
}
.winningTabMainD1{
    font-size: 12px;
    color:#4a4747;
    font-weight:400;
    font-style:italic;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:29px 0px
}
.leaderboardMain{
    padding:10px 14px;
    background-color: white;
}
.leaderboardMain input{
    width:100%;
    height: 1vh
}
.leaderboardMainDiv{
    color:#4a4747;
    font-size:12px;
    font-weight:400
}
.leaderboardMain2{
    height:auto;
    background-color:rgb(245, 245, 245)
}
.leaderboardMain2A{
    height:auto;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    padding: 0px 10px
}
.leaderboardMain2B{
    height: auto;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
    padding: 0px 10px;
    background-Color:white
}
.leaderboardMain2C{
    height:auto;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    padding: 0px 10px;
    background-color:white
}
.leaderboardMain2D{
    height: auto;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    padding: 0px 10px;
    background-color:white
}
.leaderboardMain2AP1{
    width: 10%;
    color:#212121;
    font-size: 14px;
    font-family: Roboto;
    display:flex;
    justify-content:center;
    align-items:center
}
.leaderboardMain2AP2{
    width: 40%;
    color: #212121;
    font-size: 14px;
    font-family:Roboto;
    display:flex;
    justify-content:center;
    align-items:center
}
.leaderboardMain2AP3{
    width:20%;
    color:#212121;
    font-size:14px;
    font-family: Roboto;
    display: flex;
    justify-content:center;
    align-items:center
}
.leaderboardMain2AP4{
    width: 20%;
    color: #212121;
    font-size: 14px;
    font-family: Roboto;
    display: flex;
    justify-content:center;
    align-items:center
}
.leaderboardMain2BP1{
    width: 10%;
    color: #212121;
    font-size: 14px;
    font-family: Roboto;
    display: flex;
    justify-content:center;
    align-items:center
}
.leaderboardMain2BP2{
    width: 40%;
    color:#212121;
    font-size:14px;
    font-family: Roboto;
    display:flex;
    justify-content:center;
    align-items:center
}
.leaderboardMain2BP2 img{
    width:20px;
    height:20px;
    border-radius:50%;
    margin-right:6px
}
.leaderboardMain2BP3{
    width:20%;
    color:#3f51b5;
    font-size:17px;
    font-family:Roboto;
    display:flex;
    justify-content:center;
    align-items:center
}
.leaderboardMain2BP4{
    width:20%;
    color:#212121;
    font-size:14px;
    font-family:Roboto;
    display:flex;
    justify-content:center;
    align-items:center
}