.notifyMain{
    background-color:rgb(232, 244, 253);
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:  center;
    align-items: center;
    padding: 3px 0px 16px;
    margin-top: 62px;
}
.notifyMainA{
    width: 9%;
    height: auto
}
.notifyMainB{
    width: 80%;
    height: auto
}
.notifyMainA p{
    color: rgb(33, 150, 243);
    border: 2px solid rgb(33, 150, 243);
    border-radius: 50px;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 11px
}
.notifyMainB p{
    color: #2c2c2c;
    font-size:12px;
    font-weight: 500;
    margin-bottom: 0px;
    line-height: 17px;
    font-family: Roboto
}
.contestsMain{
    height:auto;
    width:100%;
    padding: 18px 0px 9px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;
}
.contestsMainPart1{
    height: auto;
    width: 38%;
    color: #2c2c2c;
    font-weight: bold;
    font-family: Roboto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}
.contestsMainPart1 img{
    width: 14%;
    height: auto;
    margin-right: 10px
}
.contestsMainPart2{
    height: auto;
    width: 35%;
    font-family: Roboto;
    color: #959595;
    font-size: 13px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:center
}
.contestsMainPart2Link{
    display: flex;
    justify-content: center;
    align-items: baseline;
    text-decoration: none;
    color: #959595
}
.contestsMainPart2Link p{
    color: #959595;
    border: 2px solid #959595;
    border-radius:  50px;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items:center;
    font-weight: bold;
    font-size: 11px;
    margin-left: 10px
}
.contestsParts{
    height: auto;
    background-color: #f5f5f5;
    box-shadow :0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.contestsPartsA{
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center
}
.contestsPartsB{
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center
}
.contestsPartsB p{
    font-weight: 500;
    font-size: 15px
}
.freeContestMain{
   height:  auto;
   padding: 30px 23px 17px
}
.freeContestMain2{
    width: 100%;
    height: auto
}
.freeContestMain2 h5{
    color: #212529;
    font-family: Roboto;
    font-size:  18px;
    margin-top: 0%;
    margin-bottom: 6px
}
.freeContestMain2A{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
    border-radius: 10px;
    background-color: white;
    height: auto;
    padding: 0px 0px 11px;
    margin-bottom: 10px;
}
.freeContestMain2APart1{
    background: linear-gradient(35deg,#696edb,#97a9db 50%,#796ede);
    height: auto;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding:6px 0px 5px
}
.freeContestMain2APart1 p{
    width: 14px;
    height: 14px;
    border: 1px solid #fff;
    border-radius: 50%;
    font-size: 9px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0%;
    color: white;
    padding: 2px 2px 0px 2px;
    margin-top: 0%;
    margin-left:9px
}
.freeContestMain2APart2{
    height: 7vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between ;
    align-items: center
}
.freeContestMain2APart2A{
    width: 29%;
    height: auto;
    font-family: Roboto;
    color: #212529;
    font-weight: 700;
    font-size: 15px;
    display: flex;
    justify-content: center
}
.freeContestMain2APart2B{
    width: 35%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center
}
.freeContestMain2APart2B p{
    background-color: #6980a6;
    height: auto;
    box-shadow :0 2px 4px 0 rgba(0,0,0,.2),0 3px 10px 0 rgba(0,0,0,.19);
    border-radius:15px;
    width: 70px;
    color: white;
    font-weight: 700;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:7px
}
.freeContestMain2APart3{
    padding: 0px 14px
}
.freeContestMain2APart3 input{
    width: 100%;
    height: 1vh
}
.freeContestMain2APart3A{
    height: auto;
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center
}
.freeContestMain2APart3A1{
    height: 2vh;
    width: 40%;
    color: #212529;
    font-size: 12px;
    font-weight: 500;
    font-style:Roboto
}
.freeContestMain2APart3A2{
    height:2vh;
    width: 43%;
    color: #212529;
    font-size: 12px;
    font-weight: 500;
    font-style : Roboto
}
.freeContestMain2APart3A3{
    background-color:rgb(224, 224, 224);
    height: 10px
}
.mycontestsMain{
    height: auto;
    padding: 28px 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center
}
.mycontestsMain img{
   width:90%;
   height:auto
}
.mycontestsMainP1{
    color: #2c2c2c;
    font-weight:700;
    margin-top: 0%;
    margin-bottom: 3px
}
.mycontestsMainP2{
    color: #959595;
    font-weight:400;
    margin-top:0%;
    font-size:12px;
    margin-bottom:2px
}
.mycontestsMainP3{
    color:#959595;
    font-weight:400;
    margin-top:0%;
    font-size:12px
}
.whitebg{
    background-color: white;
}
.freemyContestMain{
    height:  auto;
    padding: 30px 23px 17px
 }
 .freemyContestMain2{
     width: 100%;
     height: auto
 }
 .freeContestMain2 h5{
     color: #212529;
     font-family: Roboto;
     font-size:  18px;
     margin-top: 0%;
     margin-bottom: 6px
 }
 .freemyContestMain2A{
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
     border-radius: 10px;
     background-color: white;
     height: auto;
     padding: 0px 0px 0px;
     margin-bottom: 10px;
 }
 .freemyContestMain2APart1{
     background: linear-gradient(35deg,#696edb,#97a9db 50%,#796ede);
     height: auto;
     width: 100%;
     border-top-left-radius: 10px;
     border-top-right-radius: 10px;
     padding:6px 0px 5px
 }
 .freemyContestMain2APart1 p{
     width: 14px;
     height: 14px;
     border: 1px solid #fff;
     border-radius: 50%;
     font-size: 9px;
     font-weight: 700;
     text-align: center;
     margin-bottom: 0%;
     color: white;
     padding: 2px 2px 0px 2px;
     margin-top: 0%;
     margin-left:9px
 }
 .freemyContestMain2APart2{
     height: 7vh;
     display: flex;
     flex-direction: row;
     justify-content: space-between ;
     align-items: center
 }
 .freemyContestMain2APart2A{
     width: 29%;
     height: auto;
     font-family: Roboto;
     color: #212529;
     font-weight: 700;
     font-size: 15px;
     display: flex;
     justify-content: center
 }
 .freemyContestMain2APart2B{
     width: 35%;
     height: auto;
     display: flex;
     justify-content: center;
     align-items: center
 }
 .freemyContestMain2APart2B p{
     background-color: #6980a6;
     height: auto;
     box-shadow :0 2px 4px 0 rgba(0,0,0,.2),0 3px 10px 0 rgba(0,0,0,.19);
     border-radius:15px;
     width: 70px;
     color: white;
     font-weight: 700;
     font-size: 13px;
     display: flex;
     justify-content: center;
     align-items: center;
     padding:7px
 }
 .freemyContestMain2APart3{
     padding: 0px 14px;
     padding-bottom: 8px
 }
 .freemyContestMain2APart3 input{
     width: 100%;
     height: 1vh
 }
 .freemyContestMain2APart3A{
     height: auto;
     width:100%;
     display:flex;
     flex-direction:row;
     justify-content:space-between;
     align-items:center
 }
 .freemyContestMain2APart3A1{
     height: 2vh;
     width: 40%;
     color: #212529;
     font-size: 12px;
     font-weight: 500;
     font-style:Roboto
 }
 .freemyContestMain2APart3A2{
     height:2vh;
     width: 43%;
     color: #212529;
     font-size: 12px;
     font-weight: 500;
     font-style : Roboto
 }
 .freemyContestMain2APart3A3{
     background-color:rgb(224, 224, 224);
     height: 10px
 }
 .freeContestMain2APart4{
    width:100%;
    height:auto;
    display:flex;
    flex-direction:row;
    justify-content: space-around;
    align-items:center;
    background-color:#c6c6c6;
    border-bottom-left-radius:5px;
    border-bottom-right-radius: 5px
 }
 .freeContestMain2APart41{
    width: 20%;
    height: auto;
    padding: 10px 0px;
    display: flex;
    flex-direction:column;
    justify-content: space-around;
    align-items:center
 }
 .freeContestMain2APart41P1{
    font-size :11px;
    padding: 0%;
    margin: 0%;
    margin-bottom:5px
 }
 .freeContestMain2APart41P2{
    font-size: 11px;
    padding: 0%;
    margin: 0%
 }
 .freeContestMain2APart5{
    width: 20%;
    height: auto;
    padding: 10px 0px;
    display: flex;
    flex-direction:column;
    justify-content: space-around;
    align-items:center
 }
 .freeContestMain2APart6{
    width: 20%;
    height: auto
 }
 .freeContestMain2APart6 button{
    height: auto;
    font-size: 11px;
    color: white;
    background-color: green;
    border: none;
    padding: 5px 5px;
    border-radius: 2px
 }